import React, { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import TextGroup, { WorkTypes, Image } from '../Texts'

import arrow from '../../assets/images/arrow-white.svg'
import kicks from '../../assets/images/sites/Kicks-home.webp'
import economico from '../../assets/images/sites/Economico-home.webp'
import gulbenkian from '../../assets/images/sites/Gulbenkian-home.webp'
import sonae from '../../assets/images/sites/Sonae-home.webp'
import trafiurbe from '../../assets/images/sites/Trafiurbe-home.webp'
import { Desktop, Mobile } from '../MediaQueries'
import AnimationLink from '../Link'

const HomeThree = ({ lang }) => {
  const isEn = lang === 'en'
  const [background, setBackground] = useState('')
  const [minWidth, setMinwidth] = useState('unset')
  const widthRef = useRef([])
  widthRef.current = []
  const addToRefs = el => {
    if (el && !widthRef.current.includes(el)) {
      widthRef.current.push(el)
    }
  }

  useEffect(() => {
    let width = 0
    //@TODO: find why useLayoutEffect renders with plain not styled content??
    setTimeout(() => {
      _.map(widthRef.current, el => {
        if (el.clientWidth > width) {
          width = el.clientWidth
        }
      })
      setMinwidth(width)
    }, 100)
  }, [])

  const sites = [
    {
      title: 'Kicks',
      workTypes: {
        items: ['User Experience', 'Software Development'],
      },
      to: isEn ? '/en/work/#kicks' : '/trabalho/#kicks',
      id: '#kicks',
      background: kicks,
    },
    {
      title: 'Calouste Gulbenkian Foundation',
      workTypes: {
        items: ['User Experience', 'WordPress'],
      },
      to: isEn
        ? '/en/work/#gulbenkian-foundation'
        : '/trabalho/#gulbenkian-foundation',
      id: '#gulbenkian-foundation',
      background: gulbenkian,
    },
    {
      title: 'Jornal Económico',
      workTypes: {
        items: ['WordPress', 'Support'],
      },
      to: isEn ? '/en/work/#jornal-economico' : '/trabalho/#jornal-economico',
      id: '#jornal-economico',
      background: economico,
    },
    {
      title: "Sonae Sierra Group's Shopping Centers",
      workTypes: {
        items: ['User Experience', 'WordPress'],
      },
      to: isEn ? '/en/work/#sonae-sierra' : '/trabalho/#sonae-sierra',
      id: '#sonae-sierra',
      background: sonae,
    },
    {
      title: 'Trafiurbe',
      workTypes: {
        items: ['Laravel', 'Support'],
      },
      to: isEn ? '/en/work/#trafiurbe' : '/trabalho/#trafiurbe',
      id: '#trafiurbe',
      background: trafiurbe,
    },
  ]

  return (
    <section
      id="home-three"
      className={background ? 'bg-blue has-bg-img' : 'bg-blue'}
      style={
        background
          ? {
              backgroundImage: 'url(' + background + ')',
            }
          : {}
      }
    >
      <div className="content container">
        <div className="row sites-entry">
          <TextGroup
            rootProps={{
              className: 'text-group col-5 col-12-medium font-size-big',
            }}
            title={{
              type: 'h2',
              text: isEn
                ? 'What our works look like'
                : 'Trabalhamos com empresas de topo',
            }}
          />
          <TextGroup
            rootProps={{ className: 'text-group col-5 col-12-medium' }}
            description={{
              text: isEn
                ? "We have been customer-driven since day 1, and we're always eager to dive into any tech challenge."
                : 'Desde o início que o nosso foco está no cliente, e estamos sempre ansiosos por mergulhar em qualquer desafio tecnológico.',
            }}
            cta={{
              rootProps: {
                className: 'button tertiary',
                to: isEn ? '/en/work/' : '/trabalho/',
              },
              text: isEn
                ? 'See some of our projects'
                : 'Veja alguns dos nossos trabalhos',
            }}
          />
        </div>
        <div className={background ? 'row sites is-active' : 'row sites'}>
          {_.map(sites, (site, i) => (
            <React.Fragment>
              <Mobile>
                <a
                  key={`site-${i}`}
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                  }}
                  className={'site'}
                >
                  <WorkTypes {...site.workTypes} />
                  <div className="texts">
                    <AnimationLink
                      hex="#322EFF"
                      paintDrip
                      to={site.to}
                      id={site.id}                    
                      >
                      <h2>{site.title}</h2>
                      <Image image={arrow} width={40} height={40} />
                    </AnimationLink>
                  </div>
                </a>
              </Mobile>
              <Desktop>
                <a
                  key={`site-${i}`}
                  onClick={e => {
                    e.preventDefault()
                  }}
                  onMouseEnter={() => {
                    setBackground(site.background)
                  }}
                  onMouseLeave={() => setBackground('')}
                  href="#"
                  className={
                    site.background === background ? 'site is-hover' : 'site'
                  }
                >
                  <WorkTypes
                    {...site.workTypes}
                    rootProps={{
                      ref: addToRefs,
                      style: { minWidth: minWidth },
                    }}
                  />
                  <div className="texts">
                    <AnimationLink
                      hex="#322EFF"
                      paintDrip
                      to={site.to}
                      id={site.id}
                    >
                      <h3>{site.title}</h3>
                      <Image image={arrow} width={40} height={40} />
                    </AnimationLink>
                  </div>
                </a>
              </Desktop>
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}
export default HomeThree
