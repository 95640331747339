import React from 'react'
import TextGroup from '../Texts'
import _ from 'lodash'
import { Parallax } from 'react-scroll-parallax';

import t1_logo from '../../assets/images/testimonials/ageas.svg'
import t2_logo from '../../assets/images/testimonials/bynd.svg'
import t3_logo from '../../assets/images/testimonials/kicks.svg'
import t4_logo from '../../assets/images/testimonials/filintomota.png'
import Testimonial from '../Testimonial'
import { Desktop, Mobile } from '../MediaQueries';


const HomeFour = ({ lang }) => {
  const isEn = lang === 'en';
  const testimonials = [
    {
      text:
        isEn ? 'log is an extraordinary partner. We had the opportunity to work together on designing a website for our brokers, and we have no words to describe the team\'s dedication and professionalism. If more opportunities arise in the future, I will not hesitate to work together with log.' : 'A log é um parceiro extraordinário. Tivemos a oportunidade de trabalhar juntos na criação de um website para os nossos corretores, e não temos palavras para descrever a dedicação e profissionalismo da equipa. Se surgirem mais oportunidades no futuro, não hesitarei em trabalhar com a log.',
      companyLogo: t1_logo,
      company: 'Ageas',
      person: 'Soraia Alves',
    },
    {
      text:
        isEn ? 'The log team has worked with us since we came to Portugal in 2020. As agency demands vary rapidly, they have shown tremendous flexibility in accommodating our needs, finding great talent, and delivering impactful work for our clients.' : 'A equipa da log trabalha connosco desde que chegámos a Portugal em 2020. Como as necessidades de agências variam rapidamente, a equipa mostrou uma flexibilidade tremenda no que toca a responder às nossas necessidades, a encontrar grandes talentos e a entregar trabalhos de impacto para os nossos clientes.',
      company: 'Bynd',
      companyLogo: t2_logo,
      person: 'Cassiano Surek',
    },
    {
      text:
        isEn ? 'log helped us from the very first hour of development of our new website. The work was exhaustive and started with the UX project. With a pandemic in the middle, the challenges were enormous during the implementation phase, but the working method with 10-day work steps allowed monitoring the development of the project and making adjustments in real time.' : 'A log ajudou-nos desde a primeira hora de desenvolvimento do nosso novo website. O trabalho foi aprofundado e começou pelo projeto de UX. Com uma pandemia pelo meio, os desafios na fase de implementação foram enormes, mas o método de trabalho com passos de 10 dias permitiu monitorizar o desenvolvimento do projeto e fazer ajustes em tempo real.',
      company: 'Kicks',
      companyLogo: t3_logo,
      person: 'José Vieira',
    },
    {
      text:
        isEn ? 'In 2018, we picked log as the developer of the new site for Grupo FILINTO MOTA, and we couldn\'t be more satisifed with the final result and the project\'s entire unfolding. Since then, log has become a reliable and trustworthy partner capable of making all of our ideas a reality, from the simplest to the most complex ones.' : 'Em 2018, escolhemos a log para o desenvolvimento do novo site do Grupo FILINTO MOTA, e não podíamos estar mais satisfeitos com o resultado final e com o desenrolar de todo o projecto. Desde então, a log assumiu-se como um parceiro fiável e de confiança, capaz de transformar todas as nossas ideias, da mais simples à mais complexa, em realidade.',
      company: 'Filinto Mota',
      companyLogo: t4_logo,
      person: 'Pedro Dias',
    },
  ]
  return (
    <section id="home-four" className="content container">
      <div className="row">
        <Mobile>
          <TextGroup
            rootProps={{ className: 'text-group col-4 col-12-medium font-size-big' }}
            title={{
              type: 'h2',
              text: isEn ? 'What our clients say about us' : 'O que os clientes dizem sobre nós',
            }}
          />
        </Mobile>
        <Desktop>
          <Parallax className='col-4 col-12-medium font-size-big' scaleY={-35} style={{ display: 'flex', alignSelf: 'center' }}>
            <TextGroup
              title={{
                type: 'h2',
                text: isEn ? 'What our clients say about us' : 'O que os clientes dizem sobre nós',
              }}
              description={{
                text:
                  isEn ? "We have always focused on our clients right from the start, and we're thrilled by any technological challenge. Our clients' satisfaction is what puts a smile on our face." : 'O nosso foco está nos nossos clientes desde o início, e qualquer desafio tecnológico deixa-nos entusiamados. A satisfação dos nossos clientes é o que nos deixa felizes.',
              }}
              cta={{
                rootProps: { className: 'button', to: isEn ? '/en/work/' : '/trabalho/' },
                text: isEn ? 'See some of our projects' : 'Veja alguns dos nossos projetos',
              }}
            />
          </Parallax>
          <div className="col-7 off-1 col-12-medium testimonials">
            {_.map(testimonials, (testimonial, i) => (
              <React.Fragment key={`${testimonial.company}-${i}`}>
                <Testimonial {...testimonial} rootProps={{ className: 'testimonial bg-white' }} />
              </React.Fragment>
            ))}
          </div>
        </Desktop>
        <Mobile>
          <div className="col-7 col-12-medium testimonials">
            {_.map(testimonials, (testimonial, i) => (
              <React.Fragment key={`${testimonial.company}-${i}`}>
                <Testimonial {...testimonial} rootProps={{ className: 'testimonial bg-white' }} />
              </React.Fragment>
            ))}
            <TextGroup
              rootProps={{ className: 'text-group col-12-medium' }}
              cta={{
                rootProps: { className: 'button', to: isEn ? '/en/work/' : '/trabalho/' },
                text: isEn ? 'See some of our projects' : 'Veja alguns dos nossos projetos',
              }}
            />
          </div>
        </Mobile>
      </div>
    </section>
  )
}
export default HomeFour
