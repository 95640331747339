import React from 'react'
import _ from 'lodash'
import { Mobile, Desktop } from './MediaQueries'

export const Testimonial = props => (
  <div {...props.rootProps}>
    <div className="row row-center">
      <Mobile>
        <div className="col-image col-4 col-12-medium">
          <span className="image">
            <img src={props.companyLogo} title={props.company} width="200" height="90" loading="lazy" />
          </span>
          <p>
            <b>{props.person},<br />{props.company}</b>
          </p>
        </div>
      </Mobile>
      <div className="col-text col-8 col-12-medium">
        <p>{props.text}</p>
      </div>
      <Desktop>
        <div className="col-image col-4 col-12-medium">
          <span className="image">
            <img src={props.companyLogo} title={props.company}  width="200" height="90" loading="lazy"  />
          </span>
          <p>
            <b>{props.person},<br />{props.company}</b>
          </p>
        </div>
      </Desktop>
    </div>
  </div>
)

export const TestimonialSmall = props => (
  <div {...props.rootProps}>
    <div className="row row-center">
      <p>{props.text}</p>
      <p>
        <b>{props.person}</b>{(typeof props.company !== 'undefined') ? ' - ' + props.company : ''}
      </p>
    </div>
  </div>
)

export default Testimonial
