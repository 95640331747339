import React from 'react'
import _ from 'lodash'

import tile1 from '../../assets/images/tiles/Software-Development.svg'
import tile2 from '../../assets/images/tiles/Dedicated-Teams.svg'
import tile3 from '../../assets/images/tiles/Staff-Augmentation.svg'
import tile4 from '../../assets/images/tiles/Digital-Strategy-UX.svg'
import tile5 from '../../assets/images/tiles/Tech-Audits.svg'
import tile6 from '../../assets/images/tiles/WordPress.svg'
import tile7 from '../../assets/images/tiles/Support-Maintenance.svg'
import tile8 from '../../assets/images/tiles/E-Commerce.svg'
// Support-Maintenance
// Tech-Audits



import c_logo1 from '../../assets/images/company-logos/ageas.svg'
import c_logo2 from '../../assets/images/company-logos/nos.svg'
import c_logo3 from '../../assets/images/company-logos/ericsson.svg'
import c_logo4 from '../../assets/images/company-logos/sonae.svg'
import c_logo5 from '../../assets/images/company-logos/pfizer.svg'
import c_logo6 from '../../assets/images/company-logos/worten.svg'

import TextGroup, { Image, TileGroup } from '../Texts'
import { Desktop, Mobile } from '../MediaQueries'

const HomeOne = ({ lang }) => {
  const isEn = lang === 'en';
  const tiles = [
    {
      link: {
        to: isEn ? '/en/services#software-development/' : '/servicos#software-development/',
        id: '#software-development' 
      },
      image: {
        rootProps: { className: 'image' },
        height: 56,
        width: 56,
        image: tile1,
      },
      title: {
        text: isEn ? 'Software Development' : 'Desenvolvimento de Software',
        type: 'h2',
      },
    },
    {
      rootProps: {},
      link: {
        to: isEn ? '/en/services#dedicated-teams/' : '/servicos#dedicated-teams/',
        id: '#dedicated-teams' 
      },
      image: {
        title: '',
        rootProps: { className: 'image' },
        height: 56,
        width: 56,
        image: tile2,
      },
      title: {
        text: isEn ? 'Dedicated Teams' : 'Equipas Dedicadas',
        type: 'h2',
      },
    },
    {
      link: {
        to: isEn ? '/en/services#staff-augmentation/' : '/servicos#staff-augmentation/',
        id: '#staff-augmentation' 
      },
      image: {
        rootProps: { className: 'image' },
        height: 56,
        width: 56,
        image: tile3,
      },
      title: {
        text: isEn ? 'Staff Augmentation' : 'Reforço de Equipa',
        type: 'h2',
      },
    },
    {
      rootProps: {},
      link: {
        to: isEn ? '/en/services#digital-strategy-and-ux/' : '/servicos#digital-strategy-and-ux/',
        id: '#digital-strategy-and-ux' 
      },
      image: {
        rootProps: { className: 'image' },
        height: 56,
        width: 56,
        image: tile4,
      },
      title: {
        text: isEn ? 'Digital Strategy and UX' : 'Estratégia Digital e UX',
        type: 'h2',
      },
    },
    {
      rootProps: {},
      link: {
        to: isEn ? '/en/services#tech-audits/' : '/servicos#tech-audits/',
        id: '#tech-audits' 
      },
      image: {
        rootProps: { className: 'image' },
        height: 56,
        width: 56,
        image: tile5,
      },
      title: {
        text: isEn ? 'Tech Audits' : 'Auditorias Tecnológicas',
        type: 'h2',
      },
    },
    {
      rootProps: {},
      link: {
        to: isEn ? '/en/services#wordpress/' : '/servicos#wordpress/',
        id: '#wordpress' 
      },
      image: {
        rootProps: { className: 'image' },
        height: 56,
        width: 56,
        image: tile6,
      },
      title: {
        text: 'WordPress',
        type: 'h2',
      },
    },
    {
      rootProps: {},
      link: {
        to: isEn ? '/en/services#support-maintenance/' : '/servicos#support-maintenance/',
        id: '#support-maintenance' 
      },
      image: {
        rootProps: { className: 'image' },
        height: 56,
        width: 56,
        image: tile7,
      },
      title: {
        text: isEn ? 'Support & Maintenance' : 'Suporte & Manutenção',
        type: 'h2',
      },
    },
    {
      rootProps: {},
      link: {
        to: isEn ? '/en/services#e-commerce/' : '/servicos#e-commerce/',
        id: '#e-commerce' 
      },
      image: {
        rootProps: { className: 'image' },
        height: 56,
        width: 56,
        image: tile8,
      },
      title: {
        text: 'E-commerce',
        type: 'h2',
      },
    },
  ]

  const companies = [
    {
      image: c_logo3,
      title: 'ERICSSON',
    },
    {
      image: c_logo1,
      title: 'AGEAS',
    },
    {
      image: c_logo6,
      title: 'WORTEN',
    },

    {
      image: c_logo5,
      title: 'PFIZER',
    },
    {
      image: c_logo2,
      title: 'NOS',
    },
    {
      image: c_logo4,
      title: 'SONAE',
    },
  ]

  return (
    <section id="home-one" className="content container">
      <div className="row">
        <div className="col-5 col-12-medium">
          <TextGroup
            rootProps={{ className: 'text-group' }}
            title={{
              type: 'h1',
              text: isEn ? 'Impactful Tech Solutions' : 'Soluções Tecnológicas Impactantes',
            }}
            description={{
              text:
                isEn ? 'An agile software development company' : 'Uma empresa de desenvolvimento de software ágil.',
            }}
            cta={{
              rootProps: {
                className: 'button secondary',
                to: isEn ? '/en/contact-us/' : '/contacte-nos/',
              },
              text: isEn ? "Let's talk" : 'Contacte-nos',
            }}
          />
          <Desktop>
            <div className="company">
              <p>{isEn ? 'Trusted by' : 'Marcas que confiam em nós:'} </p>
              <div className="company-images">
                {_.map(companies, (company, i) => (
                  <React.Fragment key={`${company.title}-${i}`}>
                    <Image {...company} />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </Desktop>
        </div>
        <div className={'col-7 col-12-medium tiles'}>
          {_.map(tiles, (tile, i) => (
            <React.Fragment key={`${tile.title}-${i}`}>
              <TileGroup {...tile} rootProps={{ className: 'tile' }} />
            </React.Fragment>
          ))}
        </div>
        <Mobile>
          <div className="company">
            <p>{isEn ? 'Trusted by' : 'Marcas que confiam em nós:'} </p>
            <div className="company-images">
              {_.map(companies, (company, i) => (
                <React.Fragment key={`${company.title}-${i}`}>
                  <Image {...company} />
                </React.Fragment>
              ))}
            </div>
          </div>
        </Mobile>
      </div>
    </section>
  )
}
export default HomeOne
