import React from 'react'
import TextGroup from '../Texts'
import _ from 'lodash'
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module

// Front End
import angular_logo from '../../assets/images/tools/frontend/angular.svg';
import css3_logo from '../../assets/images/tools/frontend/css3.svg';
import html5_logo from '../../assets/images/tools/frontend/html5.svg';
import js_logo from '../../assets/images/tools/frontend/js.svg';
import react_logo from '../../assets/images/tools/frontend/react.svg';
import vue_logo from '../../assets/images/tools/frontend/vue.svg';

// Back End
import java_logo from '../../assets/images/tools/backend/java.svg';
import dotnet_logo from '../../assets/images/tools/backend/dotnet.svg';
import laravel_logo from '../../assets/images/tools/backend/laravel.svg';
import php_logo from '../../assets/images/tools/backend/php.svg';
import python_logo from '../../assets/images/tools/backend/python.svg';
import wordpress_logo from '../../assets/images/tools/backend/wordpress.svg';
import nodejs_logo from '../../assets/images/tools/backend/nodejs.svg';
import golang_logo from '../../assets/images/tools/backend/golang.svg';
import scala_logo from '../../assets/images/tools/backend/scala.svg';
import rust_logo from '../../assets/images/tools/backend/rust.svg';
import graphql_logo from '../../assets/images/tools/backend/graphql.svg';

// Database
import dynamodb_logo from '../../assets/images/tools/databases/dynamodb.svg';
import mongodb_logo from '../../assets/images/tools/databases/mongodb.svg';
import mysql_logo from '../../assets/images/tools/databases/mysql.svg';
import postgresql_logo from '../../assets/images/tools/databases/postgresql.svg';
import sqlserver_logo from '../../assets/images/tools/databases/sqlserver.svg';
import oracle_logo from '../../assets/images/tools/databases/oracle.svg';
import redis_logo from '../../assets/images/tools/databases/redis.svg';
import elasticsearch_logo from '../../assets/images/tools/databases/elasticsearch.svg';
import clickhouse_logo from '../../assets/images/tools/databases/clickhouse.svg';

// Cloud
import aws_logo from '../../assets/images/tools/cloud/aws.svg';
import azure_logo from '../../assets/images/tools/cloud/azure.svg';
import google_cloud_logo from '../../assets/images/tools/cloud/google_cloud.svg';
import gitlab_logo from '../../assets/images/tools/cloud/gitlab.svg';
import jenkins_logo from '../../assets/images/tools/cloud/jenkins.svg';
import github_logo from '../../assets/images/tools/cloud/github.svg';
import docker_logo from '../../assets/images/tools/cloud/docker.svg';
import azure_pipelines_logo from '../../assets/images/tools/cloud/azure_pipelines.svg';
import kubernetes_logo from '../../assets/images/tools/cloud/kubernetes.svg';

// Message Broker
import kafka_logo from '../../assets/images/tools/message-brokers/kafka.svg';
import rabbitmq_logo from '../../assets/images/tools/message-brokers/rabbitmq.svg';

let techSlidesAll;
let techSlidesStored = false;

const techCats = {
	frontend: {
		value: 'frontend',
		label: 'Front End',
	},
	backend: {
		value: 'backend',
		label: 'Back End',
	},
	database: {
		value: 'database',
		label: 'Database',
	},
	cloud: {
		value: 'cloud',
		label: 'Cloud',
	},
	messageBroker: {
		value: 'messageBroker',
		label: 'Message Broker',
	},
};

const technologies = [
	{
		image: wordpress_logo,
		title: 'WordPress',
		category: techCats.backend.value,
	},
	{
		image: aws_logo,
		title: 'AWS',
		category: techCats.cloud.value,
	},
	{
		image: laravel_logo,
		title: 'Laravel',
		category: techCats.backend.value,
	},
	{
		image: mongodb_logo,
		title: 'MongoDB',
		category: techCats.database.value,
	},
	{
		image: react_logo,
		title: 'React',
		category: techCats.frontend.value,
	},
	{
		image: graphql_logo,
		title: 'GraphQL',
		category: techCats.backend.value,
	},
	{
		image: angular_logo,
		title: 'Angular',
		category: techCats.frontend.value,
	},
	{
		image: css3_logo,
		title: 'CSS3',
		category: techCats.frontend.value,
	},
	{
		image: html5_logo,
		title: 'HTML5',
		category: techCats.frontend.value,
	},
	{
		image: js_logo,
		title: 'JavaScript',
		category: techCats.frontend.value,
	},
	{
		image: vue_logo,
		title: 'Vue',
		category: techCats.frontend.value,
	},
	{
		image: java_logo,
		title: 'Java',
		category: techCats.backend.value,
	},
	{
		image: dotnet_logo,
		title: 'Microsoft .NET',
		category: techCats.backend.value,
	},
	{
		image: php_logo,
		title: 'PHP',
		category: techCats.backend.value,
	},
	{
		image: python_logo,
		title: 'Python',
		category: techCats.backend.value,
	},
	{
		image: nodejs_logo,
		title: 'Node JS',
		category: techCats.backend.value,
	},
	{
		image: golang_logo,
		title: 'Golang',
		category: techCats.backend.value,
	},
	{
		image: scala_logo,
		title: 'Scala',
		category: techCats.backend.value,
	},
	{
		image: rust_logo,
		title: 'Rust',
		category: techCats.backend.value,
	},
	{
		image: dynamodb_logo,
		title: 'DynamoDB',
		category: techCats.database.value,
	},
	{
		image: mysql_logo,
		title: 'MySQL',
		category: techCats.database.value,
	},
	{
		image: postgresql_logo,
		title: 'PostgreSQL',
		category: techCats.database.value,
	},
	{
		image: sqlserver_logo,
		title: 'SQL Server',
		category: techCats.database.value,
	},
	{
		image: oracle_logo,
		title: 'Oracle',
		category: techCats.database.value,
	},
	{
		image: redis_logo,
		title: 'Redis',
		category: techCats.database.value,
	},
	{
		image: elasticsearch_logo,
		title: 'Elastisearch',
		category: techCats.database.value,
	},
	{
		image: clickhouse_logo,
		title: 'Clickhouse',
		category: techCats.database.value,
	},
	{
		image: azure_logo,
		title: 'Microsoft Azure',
		category: techCats.cloud.value,
	},
	{
		image: google_cloud_logo,
		title: 'Google Cloud Platform',
		category: techCats.cloud.value,
	},
	{
		image: gitlab_logo,
		title: 'Gitlab',
		category: techCats.cloud.value,
	},
	{
		image: jenkins_logo,
		title: 'Jenkins',
		category: techCats.cloud.value,
	},
	{
		image: github_logo,
		title: 'Github',
		category: techCats.cloud.value,
	},
	{
		image: docker_logo,
		title: 'Docker',
		category: techCats.cloud.value,
	},
	{
		image: azure_pipelines_logo,
		title: 'Azure Pipelines',
		category: techCats.cloud.value,
	},
	{
		image: kubernetes_logo,
		title: 'Kubernetes',
		category: techCats.cloud.value,
	},
	{
		image: kafka_logo,
		title: 'Apache Kafka',
		category: techCats.messageBroker.value,
	},
	{
		image: rabbitmq_logo,
		title: 'RabbitMQ',
		category: techCats.messageBroker.value,
	},
];

const filterTechnologies = e => {
	let filters = document.getElementsByClassName('tech-filter');
	let selFilter = e.target;
	let filterVal = selFilter.dataset.techfilter;
	let techSlides = document.querySelectorAll('[data-tech-category]');
	let slider = document.getElementsByClassName('swiper-technologies-slider')[0];
	let sliderWrapper = slider.getElementsByClassName('swiper-wrapper')[0];
	let slidesHTML = '';

	if (!techSlidesStored) {
		techSlidesAll = techSlides;
		techSlidesStored = true;
	}

	for (let filter of filters) {
		filter.classList.remove('active');
	}

	selFilter.classList.add('active');

	for (let techSlide of techSlidesAll) {
		if (filterVal !== 'all' && techSlide.dataset.techCategory !== filterVal) {
			techSlide.remove();
		} else {
			slidesHTML += techSlide.outerHTML;
		}
	}

	sliderWrapper.innerHTML = slidesHTML;
	slider.swiper.slideTo(0);
};	

const HomeTwo = ({ lang }) => {
	const isEn = lang === 'en';
	return (
		<section id="home-two" className="bg-white">
			<div className="content container ">
				<div className="row">
					<TextGroup
						rootProps={{ className: 'text-group col-5 col-12-medium font-size-big' }}
						title={{
							type: 'h2',
							text: isEn ? 'Toolbox' : 'Ferramentas',
						}}
					/>
					<TextGroup
						rootProps={{ className: 'text-group col-5 col-12-medium' }}
						description={{
							text:
								isEn ? "We approach our challenges with an agile mindset and practices, and we are always open to reconsider our options. That said, there are a few tools that we typically choose first when it comes to our development environment." : "Abordamos os nossos desafios com uma mentalidade e práticas ágeis, e estamos sempre abertos a reavaliar as nossas opções. No entanto, existem algumas ferramentas que são as nossas primeiras escolhas quando se trata do nosso ambiente de desenvolvimento.",
						}}
						cta={{
							rootProps: {
								className: 'button',
								to: isEn ? "/en/toolbox/" : '/ferramentas/',
							},
							text: isEn ? 'Find out more about our technologies' : 'Descubra mais sobre as nossas tecnologias',
						}}
					/>
				</div>
				<div className="row">
					<div className="filters filters-technologies">
						<div className="filter tech-filter active" data-techfilter="all" onClick={filterTechnologies}>
							{isEn ? "All" : "Tudo"}
						</div>
						{_.map(techCats, (techCat, i) => (
							<div className="filter tech-filter" data-techfilter={techCat.value} onClick={filterTechnologies}>
								{techCat.label}
							</div>
						))}
					</div>
					<div className="col-12 swiper-technologies">
						<Swiper
							modules={[Navigation]}
							spaceBetween={10}
							slidesPerView="auto"
							navigation
							observer={true}
							observeParents={true}
							observeSlideChildren={true}
							resizeObserver={true}
							rebuildonupdate={true}
							className='swiper-technologies-slider'
						>
							{_.sortBy(technologies, "title").map(technology => (
								<SwiperSlide className='tech-slide active' data-tech-category={technology.category}>
									<div className='technology-item'>
										<img src={technology.image} alt={technology.title} title={technology.title} loading="lazy" width="180px" height="60px" />
										{technology.title}
									</div>
								</SwiperSlide>
							))}
						</Swiper>

					</div>
				</div>
			</div>
		</section >
	)
}
export default HomeTwo
